@import '../../styles/theme-variables.scss';

.loading-page {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100vh;
	width: 100vw;
	background: white;
	align-items: center;
	justify-items: center;
	justify-content: center;

	.loading-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		h3 {
			color: $primary-color;
			font-family: $font-stack-4;
			font-size: 32px;
			letter-spacing: -1.76px;
		}
	}

	.copyright {
		position: absolute;
		bottom: 0;
		left: 0;
		margin-left: 15px;
		font-family: $font-stack-1;
		color: $primary-color;
		font-size: 12px;
	}
}