$font-stack-1: "Roboto-Regular", sans-serif, serif;
$font-stack-2: "Roboto-Light", sans-serif, serif;
$font-stack-3: "Roboto-Medium", sans-serif, serif;
$font-stack-4: "Averta-Bold", sans-serif, serif;
$font-stack-5: "Poppins-Regular", sans-serif, serif;

$primary-color: #0071CD;
$secondary-color: #4763F2;
$accent-color: #0099FF;
$positive-color: #16A34A;
$disabled-color: #BDBDBD;
$error-color: #ff3232;
$light-color: #ECEFF1;
$input-color: #f0f9ff;
$border-color: #cfcfcf;
$blue-grey-color: #64748B;
$pending-color: #fdd835;
$complimentary-color: #f86110;
$marketplace-color: #32addd;

$admin-header-height: 64px;
$header-height: 64px;

$min-page-height: calc(100vh - 64px);

.tool-tip {
	font-family: $font-stack-2 !important;
	font-size: 11px !important;
}

.spinner {
	width: 64px;
	height: 64px;
}

@keyframes zoom-in-out {
	0% {
		opacity: 0;
		transform: scale(0);
	}
	50% {
		transform: scale(1.1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
