@import '../styles/theme-variables.scss';

body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto-Regular", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@font-face {
	font-family: 'Roboto-Regular';
	font-style: normal;
	font-weight: 400;
	src: url('../../fonts/Roboto-Regular.ttf');
	src: local('Roboto-Regular'), local('Roboto-Regular'), url('../../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto-Light';
	font-style: normal;
	src: url('../../fonts/Roboto-Light.ttf');
	src: local('Roboto-Light'), local('Roboto-Light'), url('../../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto-Medium';
	font-style: normal;
	src: url('../../fonts/Roboto-Medium.ttf');
	src: local('Roboto-Medium'), local('Roboto-Medium'), url('../../fonts/Roboto-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Averta-Bold';
	font-style: normal;
	src: url('../../fonts/Averta-Bold.otf');
	src: local('Averta-Bold'), local('Averta-Bold'), url('../../fonts/Averta-Bold.otf') format('opentype');
}

@font-face {
	font-family: 'Poppins-Regular';
	font-style: normal;
	src: url('../../fonts/Poppins-Regular.ttf');
	src: local('Poppins-Regular'), local('Poppins-Regular'), url('../../fonts/Poppins-Regular.ttf') format('truetype');
}